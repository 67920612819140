import "core-js/modules/es.array.push.js";
import * as gongju from '@/libs/gongju';
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showDialog } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { showNotify, closeNotify } from 'vant';
import 'vant/es/notify/style';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import { watch } from 'vue';
// 引入英文语言包

export default {
  props: {
    lang_list: {},
    select_lang: {},
    lang: {},
    user: {},
    time: 1
  },
  data() {
    return {
      search_value: "",
      actions: [{
        text: '上午好！欢迎来到快乐养鸡场'
      }],
      showPopover: false,
      off: [90, 60],
      bird_alimate: {
        top: '0',
        left: '-3.2rem',
        class: 0
      },
      bird_alimate1: {
        top: '1rem',
        left: '-3.2rem',
        class: 0
      },
      car_alimate: {
        left: '-3.2rem',
        class: 0
      }
    };
  },
  setup(props) {},
  created: function () {
    watch(() => this.lang, (newVal, oldVal) => {
      // console.log(`myProp changed from ${oldVal} to ${newVal}`);  
      this.zong();
    });
  },
  methods: {
    qian() {
      api.all('/api/user/user_card', {
        gz_id: 12
      }, (err, data) => {
        if (!err) {
          console.log(data);
          if (data.code == 0) {
            // this.get_imdex_data()
            // showNotify({ type: 'success', message: data.msg });
            showNotify(data.msg);
          } else {
            console.log("ok");
            showDialog({
              message: data.msg + "，+" + data.data.point,
              confirmButtonText: this.lang.t11
            }).then(() => {
              // on close
            });
          }
        }
      });
    },
    onSelect: function (action) {
      // console.log(action)
      Toast(this.lang == 0 ? 'Please enter the land to complete seeding' : this.lang == 1 ? "يرجى الدخول إلى الأرض لإكمال البذر" : "لطفاً وارد زمین شوید تا خرید را کامل کنید");
    },
    //动画总成
    zong() {
      //夜空动画
      setInterval(() => {
        if (this.bird_alimate.left == '-3.2rem') {
          this.bird_alimate.left = '7.7rem';
          this.bird_alimate1.left = '9.7rem';
          let top = gongju.getsuiji(1, 40);
          let top1 = gongju.getsuiji(20, 60);
          this.bird_alimate.top = (top / 10).toString() + 'rem';
          this.bird_alimate1.top = (top1 / 10).toString() + 'rem';
          let cla = gongju.getsuiji(3, 5);
          let cla1 = gongju.getsuiji(4, 5);
          this.bird_alimate.class = cla;
          this.bird_alimate1.class = cla1;
        } else {
          this.bird_alimate.left = '-3.2rem';
          this.bird_alimate.class = 0;
          this.bird_alimate1.left = '-3.2rem';
          this.bird_alimate1.class = 0;
        }
      }, 5200);

      //车辆懂
      setTimeout(() => {
        this.car_alimate.class = 2;
        this.car_alimate.left = "0.6rem";
        setTimeout(() => {
          this.showPopover = true;
        }, 1500);
        setTimeout(() => {
          this.car_alimate.class = 2;
          this.car_alimate.left = "8rem";
          this.showPopover = false;
        }, 2500);
      }, 500);
    },
    updateValue() {
      this.$emit('update:search_value1', this.search_value);
    },
    //切换语言
    tab_lang(lang) {
      local.saveInfo('setting_lang', lang);
      window.location.reload();
    },
    go_login: function () {
      let _this = this;
      _this.$router.push({
        name: 'login',
        query: {
          id: 0
        }
      });
    },
    go_search: function () {
      let _this = this;
      // console.log(1111);
      if (this.search_value.length <= 0) {
        showToast(this.lang['tp2']);
        return;
      }
      _this.$router.push({
        name: 'search',
        query: {
          value: this.search_value
        }
      });
    }
  }
};