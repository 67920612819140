export default {
  props: {
    lang_list: {},
    lang: {}
  },
  data() {
    return {
      search_value: "",
      not: 'Login,login,Registeremail,registeremail,language,Language,line,Line'
    };
  },
  computed: {
    routeName: function () {
      //  console.log(this.$route.name)

      return this.$route.name;
    }
  }
};