import * as config from '@/api/config'
import axios from 'axios'
import { Toast, showToast } from 'vant';
import router from '@/router'
import * as local from "@/libs/local";
import { showLoadingToast, closeToast, allowMultipleToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
// import * as local from '@/libs/local'
import 'vant/es/toast/style';
import 'vant/es/dialog/style';

axios.defaults.baseURL = config.API_ROOT
const post = axios.post
const get = axios.get
    //const put = axios.put
const del = axios.delete
const post_lang = local.readInfo('setting_lang') == undefined || local.readInfo('setting_lang') == null ? "en" : local.readInfo('setting_lang');
export function setAuthorization(auth) {
    if (auth && auth !== '') {
        axios.defaults.headers.post['Authorization'] = 'Bearer ' + auth
        axios.defaults.headers.get['Authorization'] = 'Bearer ' + auth
        axios.defaults.headers.put['Authorization'] = 'Bearer ' + auth
        axios.defaults.headers.delete['Authorization'] = 'Bearer ' + auth
        local.saveInfo("jwt", auth)

    } else {
        axios.defaults.headers.post['Authorization'] = null
        delete axios.defaults.headers.post['Authorization']
        axios.defaults.headers.get['Authorization'] = null
        delete axios.defaults.headers.get['Authorization']
        axios.defaults.headers.put['Authorization'] = null
        delete axios.defaults.headers.put['Authorization']
        axios.defaults.headers.delete['Authorization'] = null
        delete axios.defaults.headers.delete['Authorization']
    }
}

export const WECHAT_OAUTH_URL = config.API_ROOT + "/"

let cancel
let promiseArr = {}
axios.interceptors.request.use(config => {
    // 发起请求时，取消掉当前正在进行的相同请求
    if (promiseArr[config.url]) {
        promiseArr[config.url]('操作取消')
        promiseArr[config.url] = cancel
    } else {
        promiseArr[config.url] = cancel
    }
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(
    response => {
        let res = response.data
            // 凭证过期
            // console.log(res)
        if (res.code === -1) {
            local.deleteInfo('jwt')
                // setAuthorization('')
            console.log("001")
                //   router.replace({ name: 'login' })
            window.location.replace("/#/login")

            return response
        }
        return response
    },
    error => {
        console.log(error)
        showToast('Abnormal network request')
        return Promise.reject(error)
    }
)

//万能请求
export function all(url, reqData, cb) {
    //allowMultipleToast();
    showLoadingToast({
        message: 'loading...',
        forbidClick: true,
        overlay: true,
        duration: 0

    });
    reqData.lang = post_lang
    setAuthorization(local.readInfo('jwt'))
    post(url, reqData).then(res => {
        closeToast()
        if (typeof cb === 'function') {
            cb(null, res.data)
        }

    }).catch(err => {
        closeToast()
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
export function base() {
    return config.API_ROOT;
 }
//公共获取客服
export function chat(type, cb = null) {
    showLoadingToast({
        message: 'loading...',
        forbidClick: true,
        overlay: true,
        duration: 0

    });
    setAuthorization(local.readInfo('jwt'))
    post("/api/index/chat", { type: type, lang: post_lang }).then(res => {
        closeToast()
        showSuccessToast(res.data.msg)
        if (res.data.code == 1) {
            window.location.href = res.data.data.url
        }else{
            showFailToast(res.data.msg)
        }
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        closeToast()
        showToast('Abnormal network request')
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}

//公共收藏
export function shou(id, type = 0, cb = null) {
    showLoadingToast({
        message: 'loading...',
        forbidClick: true,
        overlay: true,
        duration: 0

    });
    setAuthorization(local.readInfo('jwt'))
    post("/api/index/shou", { id: id, lang: post_lang, type: type }).then(res => {
        closeToast()
        showToast(res.data.msg)
        if (res.data.code == 1) {
            if (typeof cb === 'function') {
                cb(null, res.data)
            }
        }

    }).catch(err => {
        closeToast()
        showToast('Abnormal network request')
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}
//公共加购
export function cart(id, sku_id, num, type = 0, cb = null) {
    showLoadingToast({
        message: 'loading...',
        forbidClick: true,
        overlay: true,
        duration: 0

    });
    setAuthorization(local.readInfo('jwt'))
    post("/api/index/cart", { id: id, sku_id: sku_id, num: num, type: type, lang: post_lang }).then(res => {
        closeToast()
        showToast(res.data.msg)
        if (typeof cb === 'function') {
            cb(null, res.data)
        }
    }).catch(err => {
        closeToast()
        showToast('Abnormal network request')
        if (typeof cb === 'function') {
            cb(err, null)
        }
    })
}